import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from '../components/Layout'

export default function QualityControlPlanTemplate({ data, pageContext }) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const documentName = filePathParts[2]
  return (
    <Layout title={documentName} filePath={filePath} tableOfContents="true">
      <table>
        <thead>
          <tr>
            <th colspan="3">Product</th>
            <th colspan="3">Process</th>
            <th colspan="3">Quality</th>
            <th colspan="4">Output</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>ID</strong>
            </td>
            <td>
              <strong>Part</strong>
              <br />
              <strong>Number(s)</strong>
            </td>
            <td>
              <strong>Part</strong>
              <br />
              <strong>Description</strong>
            </td>
            <td>
              <strong>Process Type</strong>
            </td>
            <td>
              <strong>Characteristic Being Controlled</strong>
            </td>
            <td>
              <strong>Procedure</strong>
              <br />
              Procedure document detailing the steps required to correctly
              complete the process
            </td>
            <td>
              <strong>Defect Severity</strong>
            </td>
            <td>
              <strong>Inspection Level</strong>
            </td>
            <td>
              <strong>AQL</strong>
            </td>
            <td>
              <strong>Quality Record</strong>
              <br />
              Where the measurement is recorded
            </td>
            <td>
              <strong>Reaction Plan</strong>
              <br />
              What to do if the measurement does not meet the specification
            </td>
            <td>
              <strong>Est. Minutes per Unit</strong>
            </td>
            <td>
              <strong>Est. Hours per Lot</strong>
            </td>
          </tr>
          {frontmatter.qualityControlPlanTable.map((row) => (
            <tr>
              <td>{row.productID}</td>
              <td>{row.partNumber}</td>
              <td>{row.partDescription}</td>
              <td>{row.processType}</td>
              <td>{row.characteristicBeingControlled}</td>
              <td>
                {row.procedureDocumentLink && (
                  <Link to={row.procedureDocumentLink}>
                    {row.procedureDocumentLink}
                  </Link>
                )}
              </td>
              <td>{row.defectSeverity}</td>
              <td>{row.inspectionLevel}</td>
              <td>{row.AQL}</td>
              <td>{row.qualityRecordLink}</td>
              <td>{row.reactionPlan}</td>
              <td>{row.estMinutesPerUnit}</td>
              <td>{row.estHoursPerLot}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Layout>
  )
}

export const query = graphql`
  query MarkdownQueryQualityControlPlan($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        qualityControlPlanTable {
          productID
          partNumber
          partDescription
          processType
          characteristicBeingControlled
          procedureDocumentLink
          defectSeverity
          inspectionLevel
          AQL
          qualityRecordLink
          reactionPlan
          estMinutesPerUnit
          estHoursPerLot
        }
      }
    }
  }
`
